import Hero from './body/hero'

function Body() {
    return (
    <>
        <Hero />
        
    </>)
}

export default Body;
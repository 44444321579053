import React from 'react';
import { ChakraProvider, theme, Box, Grid, Image } from '@chakra-ui/react'
import Body from './components/body'
import Header from './components/header'
import Footer from './components/footer';


function App() {

  return (
    
    <ChakraProvider theme={theme} >
      <Box textAlign="center" fontSize="lg" >
        <Grid minH="112vh" bgGradient="linear(to-tr, white, blue.200)" style={{backgroundPosition: 'center', backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundImage: `url("option2.png"`}}>
          <Header/>
          <Body/>
           <Footer/>
        </Grid>
      </Box>
    </ChakraProvider>
    
    );
  }


export default App;
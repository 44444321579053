import {
    Box,
    Text,
    Heading,
    Button
  } from '@chakra-ui/react';
import { Stack } from '@chakra-ui/react';
import ReactDOM from "react-dom/client";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  RedditShareButton,
  RedditIcon

} from "react-share";

import { useState } from 'react';
import { Select } from '@chakra-ui/react';
import { IconButton } from '@chakra-ui/react';
import { HStack, VStack } from '@chakra-ui/react';
import { QuestionOutlineIcon, QuestionIcon } from '@chakra-ui/icons'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
} from '@chakra-ui/react'

import { Input } from '@chakra-ui/react';

const BASE_URL = `https://k2uggwknz0.execute-api.us-west-1.amazonaws.com/default/get-festival-flow`



function Hero() {
    const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
    const [value, setValue] = useState('');
    var [festival, setValueFestival] = useState('bottlerock_2024');
    const handleChange = (event) => setValue(event.target.value); 
    const handleChangeFestival = (event) => setValueFestival(event.target.value); 

    const makeRequest = async (input) => {
      input = input.replace(" ", "").split(",");
      if (festival=="") {
        festival="bottlerock_2024";
      }
      console.log("festival " + festival)
      try {
        let body = await fetch(BASE_URL, {
          method: 'POST',
          headers: {"Access-Control-Allow-Headers": "content-type"},
          body: JSON.stringify({playlist: input, festival: festival})
        });
        let flow = await body.json()

        // error handling
        if (flow[0].hasOwnProperty('error')){
          if (flow[0].error == 'Empty playlist id'){
            throw('Please enter a playlist.')
          }
          if (flow[0].error == "You entered a user link not a playlist link"){
            throw("You entered a user link not a playlist link")
          }
          if (flow[0].error == "You entered a track link not a playlist link"){
            throw("You entered a track link not a playlist link")
          }
          if (flow[0].error == "Playlist does not exist or isn't public."){
            throw("Playlist link does not exist or isn’t public. We do not support playlist names, please enter your playlist link.")
          }
        }

        let owner = flow[0].owner
        console.log(flow[0])
        let playlist_name = flow[0].playlist_name
        console.log(playlist_name)
        const playlist_header = ReactDOM.createRoot(document.getElementById("playlist_info"));
        playlist_header.render(          
          <Text color = {'black'}><br />Based on playlist <b>{playlist_name}</b> from <b>{owner}</b> here’s your lineup</Text>
          
        )
        
        
        // create table
        const DisplayData= await flow.map(
          (info)=>{
            return(
              <tr style={{borderBottom: "1px solid white"}}>
                <td style={{fontSize:16, width:150, color:'black', textDecoration:'underline'}}><a target={"_blank" } rel={"noopener noreferrer"} href={"https://open.spotify.com/artist/"+ info.track__artists__id}>{info.track__artists__name}</a></td>
                <td style={{fontSize:16, width:100, color:'black'}}>{info.stage}</td>
                <td style={{fontSize:16, width:50, color:'black'}}>{info.dow}</td>
                <td style={{fontSize:16, width:140, color:'black'}}>{info.time}</td>
                <td className='colorText' class="colorText" style={{fontSize:16, width:180, color:'black'}}>{info.decile}</td>
              </tr>
            )
          }
        )
 
        const root = ReactDOM.createRoot(document.getElementById("table_id"));
        root.render(
          <div>
              <br />
              <table style={{borderCollapse: "collapse"}} className="table table-striped">
                  <thead>
                      <tr style={{borderBottom: '1px gray.600'}}>
                      <th style={{color:'#54B3E2', fontSize:18}}>Artist name</th>
                      <th style={{color:'#54B3E2', fontSize:18}}>Stage</th>
                      <th style={{color:'#54B3E2', fontSize:18}}>Day</th>
                      <th style={{color:'#54B3E2', fontSize:18}}>Time</th>
                      <th style={{color:'#54B3E2', fontSize:18}}></th>
                      </tr>
                  </thead>
                  <tbody>
                      {DisplayData}
                  </tbody>
              </table>
               
          </div>
      )
      
      const fb_share = ReactDOM.createRoot(document.getElementById("fb_share"));
      
      const sendGAEvent = async (platform) => {
        window.gtag('event', 'Share',
        { "event_category": "Share",
          "event_label": platform,
           "value": platform});
      }

      fb_share.render(
        <HStack>
          <Text color='white'>Share your flow</Text>
          <FacebookShareButton onClick={async () => {
                sendGAEvent("facebook");}}
                quote={'Found my perfect BOTTLEROCK schedule thanks to www.festflow.com\n'+ flow[0].note} hashtag="#FestivalFlow" url="wwww.festflow.com"><FacebookIcon size={30} round={true} ></FacebookIcon></FacebookShareButton>
          <TwitterShareButton onClick={async () => {
                sendGAEvent("twitter");}}
                title='Found my perfect BOTTLEROCK schedule thanks to ' url='www.festflow.com' hashtags={["FestivalFlow"]}><TwitterIcon size={30} round={true} ></TwitterIcon></TwitterShareButton>
          <EmailShareButton via="festflow.com" subject={'Found my perfect BOTTLEROCK schedule thanks to www.festflow.com'} body={flow[0].note+'\n'} url="wwww.festflow.com"><EmailIcon size={30} round={true} ></EmailIcon></EmailShareButton>
          <RedditShareButton onClick={async () => {
                sendGAEvent("reddit");}}
                via="festflow.com" title='Found my perfect BOTTLEROCK schedule thanks to www.festflow.com' url={flow[0].note}><RedditIcon size={30} round={true} ></RedditIcon></RedditShareButton>
        </HStack>
        )

        } catch (e) {
          alert(e)
        }        
}

    return (
    <>
      <Stack
        as={Box}
        textAlign={'center'}
        spacing={{ base: 10, md: 14}}
        py={{ base: 20, md: 16}}
        p={'5'}>
          <Heading
          fontWeight={700}
          color={'black'}
          fontSize={{base: '4xl', sm: '5xl', md: '6xl'}}
          lineHeight={'110%'}>
            Discover your favorite new band<br />
           
          </Heading>
          <Text color = {'black'}>
            FestivalFlow recommends the bands to see at your music festival based on the artists and genres in your favorite playlist.
<br /> <br />We build your ideal schedule so you can just show up and do your thing.
          </Text>
          <Stack 
            direction={'column'}
            spacing={1}
            align={'center'}
            alignSelf={'center'}
            position={'relative'}
          >
          <Text fontSize={'md'} color={'white'}>Select your festival</Text>
          <Select onChange={handleChangeFestival} variant='filled' value={festival} placeholder='BOTTLEROCK' size='md'>
          </Select>
          <HStack>
            <Text fontSize={'md'} color={'white'} >Enter your public Spotify playlist link</Text>
            <Popover>
              <PopoverTrigger>
                <IconButton aria-label='Search database' colorScheme='#DB8B54' size='sm' icon={<QuestionOutlineIcon/>} />
              </PopoverTrigger>
              <PopoverContent color='white'>
                <PopoverArrow />
                <PopoverCloseButton color='black'/>
                <PopoverHeader pt={4} fontSize={16} color='black' fontWeight='bold' border='0'>Where do I find my playlist link?</PopoverHeader>
                <PopoverBody color='black' fontSize={14}><b>1.</b> Open the Spotify app and click on your selected playlist in your library
                <b> 2.</b> Click the <b>...</b> icon and tap <b>Share</b> <b>3.</b> Tap <b>Copy link</b> and you can now paste it into FestivalFlow</PopoverBody>
              </PopoverContent>
            </Popover>
          </HStack>
          <Input
            value={value}
            onChange={handleChange}
            placeholder='Enter your playlist link here'
            variant='filled'
            color='black'
            _focus={{color:"black"}}
            placeholder='Paste the link of your favorite playlist'
            _placeholder={{ opacity: 1, color: 'dark grey'}}
            autofocus={true}
            isRequired={true}
          />
      
              <Text><br /></Text>
              <Button id="button2"
              isLoading={isWaitingForResponse}
              onClick={async () => {
                setIsWaitingForResponse(true);
                window.ReportConversion();
                makeRequest(value, Select.option).then(() => {
                  setIsWaitingForResponse(false);
                  setValue('');
                });
              }}
              loadingText="Jamming..."
              backgroundColor="#B800FF"
              _loading={{opacity:"0.85"}}
              _hover={{ bg: "#B800FF" }}
              color={"white"}
              rounded={'full'}
              px={6}
              >
                Get your lineup!
              </Button>
              <div id="playlist_info"></div>
              <div id="fb_share"></div>
              <div id="table_id"></div>
              
        </Stack>
      </Stack>
    </>)
}


export default Hero;


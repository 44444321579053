import {
    Center,
    Box,
    Image
  } from '@chakra-ui/react';

function Body() {
    return (
        // <Grid minW="100wh">
        //      <Heading paddingTop={{base:'10px', md:'15px'}} textColor={'white'}>FestivalFlow</Heading>
        //      <ColorModeSwitcher justifySelf="flex-end" />
        //  </Grid>
        // <Grid minW="100wh" textAlign={"center"} alignSelf={"center"} position="center">
        <Center>
        <Box boxSize={"250"} height="10">
            <Image paddingTop={{base:'20px', md:'5px'}} src="logo.png" alt="Festival Flow" />
        </Box>
        </Center>
        
        // </Grid>
    )
}

export default Body;